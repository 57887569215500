import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

export const isLocked = !formychat_vars.is_premium

export const countries = formychat_vars.data.countries.sort((a, b) => a.name.localeCompare(b.name)).map(country => {
    return {
        value: country.code,
        label: `<span>${country.flag} (${country.code})</span> ${country.name}`,
        name: `<span>${country.flag}</span> ${country.code}`
    }
})

export const headingSizes = { small: '16px', medium: '18px', large: '20px' }
export const textSizes = { small: '12px', medium: '14px', large: '16px' }
export const iconSizes = { small: '45px', medium: '60px', large: '80px' }

export const getSize = (size, size_custom, options = textSizes) => {
    return options[size] || (isLocked ? options.medium : (size_custom + 'px'));
}

export const defaultFormData = ( country_code ) => { 
    return {
        name: formychat_vars.user.name || '',
        email: formychat_vars.user.email || '',
        phone_number: '',
        message: '',
        country_code: country_code || '44',
    }
}

export const isDesktop = () => {
    return window.innerWidth > 768;
}

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const openWhatsapp = (to, text = '', isWeb = false, newTab = false) => {
    let url = `https://wa.me/${to}?text=${encodeURIComponent(text)}`;

    // For web version.
    if ( isWeb && isDesktop() && !isLocked ) url = `https://web.whatsapp.com/send?phone=${to}&text=${encodeURIComponent(text)}`;

    if ( newTab && !isSafari() ) {
        window.open(url, '_blank', 'noopener,noreferrer');
    } else {
        window.location.href = url;
    }
}

/**
 * Save lead to the database.
 */

export const save = async (data) => {
    console.log('Saving lead', data);
    return new Promise(async (resolve) => {
        axios.post(formychat_vars.rest_url + '/submit-form', data, {
            headers: {
                'X-WP-Nonce': formychat_vars.rest_nonce,
            }
        }).then(response => {
            console.log('Lead saved', response.data);
            resolve(response.data)
        })
        .catch(error => {
            console.error('Error saving lead', error);
            resolve(error)
        })
    })
}


/**
 * Meta data.
 */
export const meta = ref({
    device: isDesktop() ? 'Desktop' : 'Mobile',
    platform: navigator.platform,
    os: navigator.oscpu,
    browser: navigator.appCodeName,
    vendor: navigator.vendor,
    language: navigator.language,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    screen: `${window.screen.width}x${window.screen.height}`,
    referrer: document.referrer,
    url: window.location.href,
})

onMounted( async () => {
    let ip = await fetchIP()
    meta.value = { ...meta, ...ip }
})

// Fetch IP Address.
export const fetchIP = async () => {
    let data = (await axios.get( formychat_vars.rest_url + '/ip-address')).data
    if ( data.status === 'success' ) {
        // return only country, regionName, city, lat, lon, isp, org,
        return {
            country: data.country,
            region: data.regionName,
            city: data.city,
            lat: data.lat,
            lon: data.lon,
            isp: data.isp,
            org: data.org,
        }
    }

    return {}
}


// // Catch CF7 form submission.
// export const CF7Submitted = ( widgets ) => {

//     document.addEventListener('wpcf7submit', async function (event) {
//         const details = event.detail;

//         if ( details.status !== 'mail_sent' ) return;

//         console.log('CF7 submitted', details, widgets);

//         const widget = widgets.find(w => {
//             return w.config.form.mode === 'cf7' && w.config.cf7.form_id === details.contactFormId
//         })

//         if ( !widget ) return;

//         const inputs = {}

//         event.detail.inputs.forEach(input => {

//             // Your-Name to name
//             if ( input.name === 'your-name' ) input.name = 'name';
//             if ( input.name === 'your-email' ) input.name = 'email';
//             if ( input.name === 'your-phone' ) input.name = 'phone_number';
//             if ( input.name === 'your-message' ) input.name = 'message';

//             inputs[input.name] = input.value
//         })

//         // Save lead.
//         let data = {
//             source: 'cf7',
//             cf7_id: widget.config.cf7.form_id,
//             field: inputs,
//             meta: meta.value,
//         }

//         await save(data);

//         let message = details.apiResponse.formychat_message || '';

//         openWhatsapp( ( widget.config.whatsapp.country_code + widget.config.whatsapp.number + '' ), message, widget.config.whatsapp.web_version, true );

//     }, false);
// }