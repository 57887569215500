import { save, openWhatsapp, meta, isDesktop } from './../store.js';

(function(){
    /** Catch CF7 Form */
    const catchForm = async ( e ) => {

        // Bail if not successful
        if ( e.detail.apiResponse.status !== 'mail_sent' ) return;

        const settings = e.detail.apiResponse.formychat || {}

        // Collect lead data
        const inputs = e.detail.inputs || []

        // Save inputs.
        let data = {
            source: 'cf7',
            cf7_id: e.detail.contactFormId,
            field: Object.fromEntries(inputs.map(input => [input.name, input.value])),
            meta: meta.value,
        }

        // console.log('Saving', data, settings);
        await save(data);

        // Bail, if settings not found or settings.status is not 'on'

        // Closest .formychat-widget-custom-form of the e.target
        const form = e.target.closest('.formychat-widget-custom-form');
        
        // If form is found, then use that.
        if ( !form && settings.status !== 'on' ) return;

        // Open whatsapp.
        let message = settings.message || '';
        // Prepare message
        inputs.forEach(input => {
            message = message.replace(`[${input.name}]`, input.value)
        })

        // If closest element having data-whatsapp attribute, then use that.
        const closest = e.target.closest('[data-whatsapp]');

        if ( closest ) {
            const whatsapp = closest.getAttribute('data-whatsapp');
            if ( whatsapp ) {
                console.log("Found whatsapp", whatsapp);

                const newTab = closest.getAttribute('data-new-tab');
                
                openWhatsapp( whatsapp, message, isDesktop(), newTab == 1 );
                return;
            }
        } 
        
        openWhatsapp( ( settings.country_code + settings.number + '' ), message, isDesktop(), 'on' == settings.new_tab );

    }

    document.addEventListener('wpcf7submit', catchForm);
})()